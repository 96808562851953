import {
  Alignment,
  ButtonType,
  DEFAULT_GRID_CARD_SPACING,
  ImageRatio,
  LayoutType,
} from './settingsConstants';
import { GroupsRequestSort } from '@wix/social-groups-api';
import { ISettingsParam } from '@wix/yoshi-flow-editor/tpa-settings';

export type ISettingsParams = {
  groupListTitle: ISettingsParam<string>;
  groupMembersLabel: ISettingsParam<string>;

  showHeaderTitle: ISettingsParam<boolean>;
  showSearchSorting: ISettingsParam<boolean>;
  showMemberCount: ISettingsParam<boolean>;
  showAdminBadge: ISettingsParam<boolean>;
  showImage: ISettingsParam<boolean>;
  mobileShowHeaderTitle: ISettingsParam<boolean>;
  mobileShowSearchSorting: ISettingsParam<boolean>;
  mobileShowMemberCount: ISettingsParam<boolean>;
  mobileShowAdminBadge: ISettingsParam<boolean>;
  mobileShowImage: ISettingsParam<boolean>;

  imageRatio: ISettingsParam<ImageRatio>;
  groupListLayout: ISettingsParam<LayoutType>;
  gridCardSpacing: ISettingsParam<number>;
  headerAlignment: ISettingsParam<Alignment>;
  listCardSpacing: ISettingsParam<number>;
  mobileImageRatio: ISettingsParam<ImageRatio>;
  mobileGroupListLayout: ISettingsParam<LayoutType>;
  mobileListCardSpacing: ISettingsParam<number>;
  mobileGridCardSpacing: ISettingsParam<number>;

  buttonType: ISettingsParam<ButtonType>;

  sortBy: ISettingsParam<GroupsRequestSort>;
};

export const settingsParams: ISettingsParams = {
  /**
   * Text settings
   */
  groupListTitle: {
    /* null used for default value, because empty string is valid custom value */
    getDefaultValue: () => null as any,
    key: 'groupListTitle',
  },
  groupMembersLabel: {
    getDefaultValue: () => 'Members',
    key: 'groupMembersLabel',
  },

  /**
   * Display settings
   */
  showHeaderTitle: {
    getDefaultValue: () => true,
    key: 'showHeaderTitle',
  },
  showSearchSorting: {
    getDefaultValue: () => true,
    key: 'showSearchSorting',
  },
  showMemberCount: {
    getDefaultValue: () => true,
    key: 'showMemberCount',
  },
  showAdminBadge: {
    getDefaultValue: () => true,
    key: 'showAdminBadge',
  },
  showImage: {
    getDefaultValue: () => true,
    key: 'showImage',
  },
  mobileShowHeaderTitle: {
    getDefaultValue: () => true,
    key: 'mobileShowHeaderTitle',
  },
  mobileShowSearchSorting: {
    getDefaultValue: () => true,
    key: 'mobileShowSearchSorting',
  },
  mobileShowMemberCount: {
    getDefaultValue: () => true,
    key: 'mobileShowMemberCount',
  },
  mobileShowAdminBadge: {
    getDefaultValue: () => true,
    key: 'mobileShowAdminBadge',
  },
  mobileShowImage: {
    getDefaultValue: () => true,
    key: 'mobileShowImage',
  },

  /**
   * Layout settings
   */
  groupListLayout: {
    getDefaultValue: () => LayoutType.list,
    key: 'groupListLayout',
  },
  mobileGroupListLayout: {
    getDefaultValue: () => LayoutType.list,
    key: 'mobileGroupListLayout',
  },
  headerAlignment: {
    getDefaultValue: () => Alignment.left,
    key: 'headerAlignment',
  },
  imageRatio: {
    getDefaultValue: () => ImageRatio.rectangle,
    key: 'imageRatio',
  },
  mobileImageRatio: {
    getDefaultValue: () => ImageRatio.rectangle,
    key: 'mobileImageRatio',
  },
  gridCardSpacing: {
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    key: 'gridCardSpacing',
  },
  mobileGridCardSpacing: {
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    key: 'mobileGridCardSpacing',
  },
  listCardSpacing: {
    getDefaultValue: () => 0,
    key: 'listCardSpacing',
  },
  mobileListCardSpacing: {
    getDefaultValue: () => 0,
    key: 'mobileListCardSpacing',
  },

  /**
   * Design settings
   */
  buttonType: {
    getDefaultValue: () => ButtonType.rectangle,
    key: 'buttonType',
  },

  /**
   * Settings settings
   */
  sortBy: {
    getDefaultValue: () => GroupsRequestSort.RECENT_ACTIVITY,
    key: 'sortBy',
  },
};
